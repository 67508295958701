var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"radius-card pb-3",attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"ma-0 pa-3"},[_c('h3',[_vm._v("Article")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","single-line":"","solo":"","rounded":"","hide-details":""},on:{"keyup":_vm.fetchItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.article)?_c('div',{staticClass:"3"},[_c('v-data-table',{staticClass:"elevation-0 my-5",attrs:{"headers":_vm.headers,"items":_vm.article.data,"hide-default-footer":"","page":_vm.page,"items-per-page":_vm.perPage},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.creator",fn:function(ref){
var item = ref.item;
return _vm._l((item.relationships),function(creator,idx){return _c('div',{key:(idx + "-user")},[(creator.user)?_c('p',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(creator.user.data.nama_lengkap)+" ")]):_vm._e()])})}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
            arrow: true,
            arrowType: 'round',
            animation: 'fade',
            theme: 'light',
            maxWidth: 100
          }),expression:"{\n            arrow: true,\n            arrowType: 'round',\n            animation: 'fade',\n            theme: 'light',\n            maxWidth: 100\n          }"}],attrs:{"color":"blue","outlined":"","content":"view image","dark":"","rounded":""},on:{"click":function($event){return _vm.viewImg(item)}}},[_vm._v(_vm._s(item.image))])]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.created_at).format("DD, MMMM, YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 100
            }),expression:"{\n              arrow: true,\n              arrowType: 'round',\n              animation: 'fade',\n              theme: 'light',\n              maxWidth: 100\n            }"}],staticClass:"mr-2",attrs:{"icon":"","depressed":"","outlined":"","color":"green","content":"View Article","to":("/article/" + (item.id) + "/view")}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-eye ")])],1)],1)]}}],null,true)}),_c('div',[_c('UpdateArticle',{attrs:{"editArticle":_vm.editArticle,"item":_vm.item},on:{"close":_vm.closeDialog}}),_c('ViewImageArticle',{attrs:{"viewImage":_vm.viewImage,"item":_vm.item},on:{"close":_vm.closeDialog}})],1),_c('div',{staticClass:"text-center pa-3"},[_c('v-pagination',{attrs:{"circle":"","length":_vm.article.meta.last_page},on:{"input":_vm.fetchItems},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }