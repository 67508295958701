<template>
  <v-card class="radius-card pb-3" :loading="loading">
    <v-card-title class="ma-0 pa-3">
      <h3>Article</h3>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        @keyup="fetchItems"
        dense
        single-line
        solo
        rounded
        hide-details
      ></v-text-field>
    </v-card-title>

    <div class="3" v-if="article">
      <v-data-table
        :headers="headers"
        :items="article.data"
        hide-default-footer
        :page.sync="page"
        :items-per-page="perPage"
        class="elevation-0 my-5"
      >
        <template v-slot:[`item.creator`]="{ item }">
          <div
            v-for="(creator, idx) in item.relationships"
            :key="`${idx}-user`"
          >
            <p v-if="creator.user" class="ma-0">
              {{ creator.user.data.nama_lengkap }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <v-btn
            color="blue"
            outlined
            content="view image"
            v-tippy="{
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 100
            }"
            dark
            rounded
            @click="viewImg(item)"
            >{{ item.image }}</v-btn
          >
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          {{ $date(item.created_at).format("DD, MMMM, YYYY") }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-btn
              icon
              depressed
              outlined
              color="green"
              class="mr-2"
              content="View Article"
              v-tippy="{
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 100
              }"
              :to="`/article/${item.id}/view`"
            >
              <v-icon dark small> mdi-eye </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>

      <div>
        <UpdateArticle
          @close="closeDialog"
          v-bind:editArticle="editArticle"
          v-bind:item="item"
        />
        <ViewImageArticle
          @close="closeDialog"
          v-bind:viewImage="viewImage"
          v-bind:item="item"
        />
      </div>

      <div class="text-center pa-3">
        <v-pagination
          v-model="page"
          circle
          @input="fetchItems"
          :length="article.meta.last_page"
        ></v-pagination>
      </div>
    </div>
  </v-card>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import UpdateArticle from "../Psycholog/Modal/editArticle";
import ViewImageArticle from "../Psycholog/Modal/viewImageArticle.vue";

export default {
  name: "articleManagement",

  components: {
    UpdateArticle,
    ViewImageArticle
  },

  computed: {
    ...mapState({
      article: state => state.library.article,
      env: state => state.API_URL
    })
  },

  data() {
    return {
      editArticle: false,
      viewImage: false,
      item: null,
      search: "",
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "TITLE", value: "judul" },
        { text: "CREATED BY", value: "creator" },
        { text: "COVER", value: "image" },
        {
          text: "CREATED AT",
          value: `created_at`
        },
        { text: "ACTIONS", value: "actions" }
      ],
      page: 1,
      perPage: 5
    };
  },

  mounted() {
    this.fetchItems(); //get all data when load page
  },

  methods: {
    fetchItems() {
      //get all data  fucntion
      this.loading = true;
      this.$store
        .dispatch("library/publicArticle", {
          page: this.page,
          limit: this.perPage,
          find: this.search
        })
        .then(() => {
          this.loading = false;
        });
    },

    updateItem(item) {
      this.item = item;
      this.editArticle = true;
    },
    viewImg(item) {
      this.item = `${this.env}/artikel/upload/${item.user_id}/${item.image}`;
      this.viewImage = true;
    },

    deleteItem(item) {
      //delete  function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store
            .dispatch("library/deleteArticle", { id: item.id })
            .then(() => {
              this.fetchItems(); //if success refetch data all
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            })
            .catch(err => {
              Swal.fire({
                title: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.editArticle = false; //set variable dialog to false
      this.viewImage = false;
      this.fetchItems(); //and refetch data
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
