<template>
  <v-row justify="start">
    <v-dialog v-model="viewImage" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">View Image</span>
          <v-spacer></v-spacer>
          <v-btn fab x-small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-container class="pa-0">
            <div v-if="item">
              <v-img :src="item" height="500px" class="img-fit"></v-img>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "viewImage",

  props: ["viewImage", "item"],

  data() {
    return {
      loading: false
    };
  },

  methods: {}
};
</script>

<style scoped></style>
