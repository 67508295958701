<template>
  <v-row justify="start">
    <v-dialog v-model="editArticle" scrollable persistent max-width="800px">
      <v-card class="radius-card" v-if="item">
        <v-toolbar dense flat>
          <span class="headline">Edit Article</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <div class="upload-btn-wrapper">
                  <div class="temp-cover d-flex align-center justify-center">
                    <div v-if="!dp">
                      <img
                        :src="`${env}/artikel/upload/${id}/${item.image}`"
                        width="100%"
                        height="200px"
                        class="img-fit"
                        alt="display picture"
                      />
                    </div>
                    <div v-if="dp">
                      <img
                        :src="`${dp}`"
                        width="100%"
                        height="200px"
                        class="img-fit"
                        alt="display picture"
                      />
                    </div>
                  </div>
                </div>
                <v-btn outlined color="green" rounded>
                  <div class="upload-btn-wrapper add-width">
                    <div class="pa-1">
                      <v-icon class="mr-1"> mdi-pencil-box </v-icon>
                      Change Picture
                      <input
                        type="file"
                        @change="getPic($event)"
                        name="myfile"
                      />
                    </div>
                  </div>
                </v-btn>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  label="Title"
                  required
                  solo
                  v-model="item.judul"
                ></v-text-field>
                <vue-editor
                  placeholder="Start Writing!"
                  :editorToolbar="customToolbar"
                  class="mb-3"
                  v-model="item.isi"
                ></vue-editor>
                <h4 class="mb-3">Category :</h4>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select
                      solo
                      hide-details="auto"
                      item-text="state"
                      item-value="val"
                      label="Public"
                      :items="opt"
                      v-model="item.visible"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      solo
                      hide-details="auto"
                      item-text="nama"
                      item-value="nama"
                      :items="listCategory"
                      v-model="item.nama_kategori"
                      label="Pilih Kategory"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div v-if="loading">
              <Waiting />
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateItem">
            <v-icon class="mr-2">mdi-tooltip-check</v-icon>
            Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
// import ImageEmpty from '../_base/Modal/imageEmpty.vue';
import { mapState } from "vuex";
import Waiting from "../../_base/Modal/waiting";
export default {
  name: "updateArticle",

  props: ["editArticle", "item"],
  computed: {
    ...mapState({
      listCategory: state => state.library.category,
      env: state => state.API_URL,
      id: state => state.id
    })
  },
  components: {
    VueEditor,
    // ImageEmpty,
    Waiting
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ],
      pic: null,
      dp: null,
      title: "",
      article: "",
      category: "Psikologi Klinis",
      dialogEmpty: false,
      opt: [
        { state: "public", abbr: "public" },
        { state: "private", abbr: "private" }
      ],
      loading: false
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    getCategory() {
      this.$store.dispatch("library/listCategory");
    },
    getPic(e) {
      const file = e.target.files[0];
      const fr = new FileReader();
      fr.onload = f => {
        this.dp = f.target.result;
      };
      fr.readAsDataURL(file);
      this.pic = file;
    },
    updateItem() {
      this.loading = true;
      const vm = this;
      const id = vm.item.id;
      let data = new FormData();
      data.append("judul", vm.item.judul);
      data.append("isi", vm.item.isi);
      data.append("nama_kategori", vm.item.nama_kategori);
      data.append("visible", vm.item.visible);
      if (vm.pic) {
        data.append("objImage", vm.pic);
      }
      vm.$store
        .dispatch("library/updateArticle", { data: data, id: id })
        .then(data => {
          this.loading = false;
          vm.$emit("close");
          vm.$emit("refetch");
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            timer: 3000,
            toast: true
          });
        })
        .catch(err => {
          Swal.fire({
            title: err.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
            position: "top",
            toast: true
          });
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.temp-cover {
  width: 100%;
  height: 200px;
  border: 1px #000 dotted;
}
</style>
